import { SearchResult } from '../../../app/pages/SearchPage/SearchPage.types';

export enum AsyncStatus {
  INITIAL,
  LOADING,
  LOADED,
}

export interface SearchState {
  status: AsyncStatus;
  results: Array<SearchResult>;
}
