import { SearchBar } from '../../components/SearchBar';
import { useSearchParams } from 'react-router-dom';
import { SearchResultView } from '../../components/SearchResultView/SearchResultView';
import { FeedbackBar } from '../../components/FeedbackBar';
import { useSelector } from 'react-redux';
import { selectSearchResults } from '../../../stores/GlobalSearch/slice/selectors';

export function SearchPage() {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query') ?? undefined;
  const searchResults = useSelector(selectSearchResults);

  return (
    <div className="_SearchPage flex h-screen w-full flex-col items-center gap-y-2">
      <div className="_Header flex h-10 w-full shrink-0 flex-row items-center justify-between self-start px-3">
        <a className="_Left flex w-40 text-[32px] font-bold" href={'/'}>
          aistock
        </a>
        <SearchBar initialQuery={query} />
        <div className="_Right flex w-40 flex-row justify-end">
          <div className="_Container flex flex-row gap-x-2 text-[16px] font-medium">
            <div>Pricing</div>
            <div>Enterprise</div>
            <div>Sign Up</div>
          </div>
        </div>
      </div>

      <div className="_SearchResults grid w-full flex-1 grid-cols-4 gap-4 overflow-auto px-4">
        {searchResults &&
          searchResults.map((searchResult, index) => <SearchResultView searchResult={searchResult} key={index} />)}
      </div>

      <FeedbackBar query={query ?? ''} />
    </div>
  );
}
