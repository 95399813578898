import { SearchResult } from '../../pages/SearchPage/SearchPage.types';
import { useNavigate } from 'react-router-dom';

interface Props {
  searchResult: SearchResult;
}

export function SearchResultView(props: Props) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/media?id=${encodeURIComponent(props.searchResult.id)}`);
  };

  return (
    <div
      className="relative cursor-pointer overflow-hidden rounded-[4px] bg-gray-200"
      style={{ paddingBottom: '56.25%' }}
      onClick={handleClick}
    >
      <div className="absolute left-0 top-0 flex size-full items-center justify-center bg-black bg-opacity-50 text-white">
        {props.searchResult.label}
      </div>
      <img
        src={props.searchResult.previewUrl}
        alt={props.searchResult.id}
        className="absolute inset-0 size-full object-cover"
      />
    </div>
  );
}
