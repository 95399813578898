import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { submitQuery } from '../../../helpers/submitQuery';
import { SearchResult } from '../../pages/SearchPage/SearchPage.types';
import { useDispatch } from 'react-redux';
import { searchActions } from '../../../stores/GlobalSearch/slice';

interface Props {
  initialQuery?: string;
}

export function SearchBar(props: Props) {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(props.initialQuery ?? '');
  const modality = 'Images';
  const navigate = useNavigate();

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    if (inputValue) {
      navigate(`/search?query=${encodeURIComponent(inputValue)}`);
    }

    submitQuery(inputValue).then((data: any) => {
      console.info(data);
      const newSearchResults: SearchResult[] = [];
      for (const item of data.media_items) {
        const searchResult: SearchResult = {
          id: item.image_url,
          label: item.original_prompt,
          previewUrl: item.thumbnail_url,
          credits: 10,
          score: item.score,
        };
        newSearchResults.push(searchResult);
      }
      dispatch(searchActions.updateResults(newSearchResults));
    });
  };

  return (
    <div className="_SearchBar flex h-6 w-[800px] flex-row gap-x-2 rounded-[12px] bg-white p-0.5">
      <div className="_ModalitySelector flex h-full cursor-pointer flex-col justify-center rounded-[10px] bg-[#F2F2F2] px-2 font-semibold">
        {modality}
      </div>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        autoFocus
        className="_SearchInput flex grow flex-col justify-center font-medium outline-none"
        placeholder="A labrador catching a frisbee on Manhattan beach"
      />
      {inputValue && (
        <button
          className="ml-2 cursor-pointer rounded-[10px] bg-blue-500 px-3 py-1 font-semibold text-white"
          onClick={handleSubmit}
        >
          Search
        </button>
      )}
    </div>
  );
}
