import { Route, Routes } from 'react-router-dom';
import { HomePage } from '../../pages/HomePage';
import { RoutePaths } from './constants';
import { SearchPage } from '../../pages/SearchPage';
import { MediaPage } from '../../pages/MediaPage';

export function RoutesSwitch() {
  return (
    <Routes>
      <Route path={RoutePaths.Home} element={<HomePage />} />
      <Route path={RoutePaths.Search} element={<SearchPage />} />
      <Route path={RoutePaths.Media} element={<MediaPage />} />
      <Route path="*" element={<div>Site not found!</div>} />
    </Routes>
  );
}
