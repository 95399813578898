import { useEffect, useRef, useState } from 'react';
import { submitFeedback } from '../../../helpers/submitFeedback';

export enum FeedbackState {
  YES,
  NO,
}

interface Props {
  query: string;
}

export function FeedbackBar(props: Props) {
  const [isSubmitted, setIsSubmitted] = useState<boolean>();
  const prevQueryRef = useRef('');

  useEffect(() => {
    if (prevQueryRef.current !== props.query) {
      setIsSubmitted(false);
      prevQueryRef.current = props.query;
    }
  }, [props.query]);

  const handleSubmit = (state: FeedbackState) => {
    setIsSubmitted(true);

    submitFeedback(state, props.query, '').then(data => console.info(data));
  };

  return (
    <div className="_FeedbackBar fixed bottom-0 left-1/2 flex h-6 w-[800px] -translate-x-1/2 -translate-y-1/2 flex-row justify-between gap-x-2 rounded-[12px] bg-white p-0.5">
      <div className="_Question flex h-full flex-col justify-center px-2 font-semibold">
        {!isSubmitted && <>Were you able to find what you're looking for?</>}
        {isSubmitted && <>Thanks for your feedback!</>}
      </div>
      {!isSubmitted && (
        <div className="flex h-full flex-row gap-x-1 self-end">
          <button
            className="_YesButton flex h-full cursor-pointer flex-col justify-center rounded-[10px] bg-[#F2F2F2] px-3 font-semibold"
            onClick={() => handleSubmit(FeedbackState.YES)}
          >
            Yes
          </button>
          <button
            className="_NoButton flex h-full cursor-pointer flex-col justify-center rounded-[10px] bg-[#F2F2F2] px-3 font-semibold"
            onClick={() => handleSubmit(FeedbackState.NO)}
          >
            No
          </button>
        </div>
      )}
    </div>
  );
}
