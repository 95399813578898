export async function getMediaCount(): Promise<number> {
  return new Promise(async (resolve, reject) => {
    const apiUrl = 'https://75nxd8kcm5.execute-api.us-east-1.amazonaws.com/Prod/count';

    try {
      const res = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });

      if (!res.ok) {
        throw new Error(`Error: ${res.status}`);
      }

      const data = await res.json();
      resolve(data.count);
    } catch (error) {
      console.error('Error:', error);
      reject(error);
    }
  });
}
