import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncStatus, SearchState } from './types';
import { SearchResult } from '../../../app/pages/SearchPage/SearchPage.types';
import { useInjectReducer } from '../../../utils/redux/redux-injectors';

export const initialState: SearchState = {
  status: AsyncStatus.INITIAL,
  results: [],
};

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateStatus(state, { payload }: PayloadAction<AsyncStatus>) {
      state.status = payload;
    },
    updateResults(state, { payload }: PayloadAction<Array<SearchResult>>) {
      state.results = payload;
    },
    clearResults(state, _) {
      state.results = [];
    },
  },
});

export const { actions: searchActions } = slice;

export const useSearchSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
