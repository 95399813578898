import { FeedbackState } from '../app/components/FeedbackBar';

const API_URL = 'https://75nxd8kcm5.execute-api.us-east-1.amazonaws.com/Prod/feedback';

export async function submitFeedback(feedback: FeedbackState, query: string, message: string) {
  return new Promise(async (resolve, reject) => {
    const feedbackStr = feedback === FeedbackState.YES ? 'YES' : 'NO';

    try {
      const res = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ feedback: feedbackStr, message: '', query: query }),
      });

      if (!res.ok) {
        throw new Error(`Error: ${res.status}`);
      }

      const data = await res.json();
      resolve(data);
    } catch (error) {
      console.error('Error:', error);
      reject(error);
    }
  });
}
