import { useNavigate } from 'react-router-dom';

interface Props {}

export function MenuBar(props: Props) {
  const navigate = useNavigate();

  return (
    <div className="_FeedbackBar fixed bottom-0 left-1/2 flex h-6 w-[1000px] -translate-x-1/2 -translate-y-1/2 flex-row justify-between gap-x-2 rounded-[12px] bg-white p-0.5">
      <div className="_Left flex h-full flex-row gap-x-1 self-start">
        <button className="_YesButton flex h-full cursor-pointer flex-col justify-center rounded-[10px] bg-[#F2F2F2] px-3 font-semibold">
          Upscale
        </button>
        <button className="_NoButton flex h-full cursor-pointer flex-col justify-center rounded-[10px] bg-[#F2F2F2] px-3 font-semibold">
          Inpaint
        </button>
        <button className="_NoButton flex h-full cursor-pointer flex-col justify-center rounded-[10px] bg-[#F2F2F2] px-3 font-semibold">
          Modify
        </button>
        <button className="_NoButton flex h-full cursor-pointer flex-col justify-center rounded-[10px] bg-[#F2F2F2] px-3 font-semibold">
          Insert object
        </button>
      </div>
      <div className="_Right flex h-full flex-row gap-x-1 self-end">
        <button
          className="_YesButton flex h-full cursor-pointer flex-col justify-center rounded-[10px] bg-[#F2F2F2] px-3 font-semibold"
          onClick={() => navigate(-1)}
        >
          Discard
        </button>
        <button
          className="_YesButton flex h-full cursor-pointer flex-col justify-center rounded-[10px] bg-[#F2F2F2] px-3 font-semibold"
          onClick={() => navigate(-1)}
        >
          Save
        </button>
      </div>
    </div>
  );
}
