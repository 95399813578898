import { SearchBar } from '../../components/SearchBar';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSearchResults } from '../../../stores/GlobalSearch/slice/selectors';
import { MenuBar } from '../../components/MenuBar';

export function MediaPage() {
  const [searchParams] = useSearchParams();
  const searchResults = useSelector(selectSearchResults);
  const media_id = searchParams.get('id') ?? undefined;
  const media = searchResults.find(sr => sr.id === media_id) ?? null;

  return (
    <div className="_MediaPage flex w-full flex-col items-center gap-y-2">
      <div className="_Header flex h-10 w-full shrink-0 flex-row items-center justify-between self-start px-3">
        <a className="_Left flex w-40 text-[32px] font-bold" href={'/'}>
          aistock
        </a>
        <SearchBar initialQuery={''} />
        <div className="_Right flex w-40 flex-row justify-end">
          <div className="_Container flex flex-row gap-x-2 text-[16px] font-medium">
            <div>Pricing</div>
            <div>Enterprise</div>
            <div>Sign Up</div>
          </div>
        </div>
      </div>
      {media && (
        <div className="w-full">
          <img src={media.previewUrl} alt={media.label} className="w-full object-cover" />
          <div className="mt-2 w-full px-4 text-sm text-gray-600">
            <div>
              <strong>Prompt:</strong> {media.label}
            </div>
            <div>
              <strong>Score:</strong> {media.score}
            </div>
          </div>
        </div>
      )}
      <MenuBar />
    </div>
  );
}
