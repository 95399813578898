export async function submitQuery(query: string) {
  return new Promise(async (resolve, reject) => {
    const apiUrl = 'https://75nxd8kcm5.execute-api.us-east-1.amazonaws.com/Prod/query';

    try {
      const res = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: query, modality: 'IMAGE', count: 12 }),
      });

      if (!res.ok) {
        throw new Error(`Error: ${res.status}`);
      }

      const data = await res.json();
      resolve(data);
    } catch (error) {
      console.error('Error:', error);
      reject(error);
    }
  });
}
