import { SearchBar } from '../../components/SearchBar';
import { useEffect, useState } from 'react';
import { getMediaCount } from '../../../helpers/getMediaCount';

export function HomePage() {
  const [mediaCount, setMediaCount] = useState<null | number>(null);

  useEffect(() => {
    getMediaCount().then(count => setMediaCount(count));
  }, []);

  return (
    <div className="_HomePage flex size-full flex-col items-center">
      <div className="_Header flex h-10 w-full flex-row items-center justify-between px-3">
        <a className="_Left flex text-[32px] font-bold" href={'/'}>
          aistock
        </a>
        <div className="_Right flex flex-row gap-x-2 text-[16px] font-medium">
          <div>Pricing</div>
          <div>Enterprise</div>
          <div>Sign Up</div>
        </div>
      </div>
      <div className="_Wrapper flex h-full flex-col justify-center">
        <div className="_Container flex flex-col items-center gap-y-10">
          <div className="_TitleContainer flex w-[700px] flex-col gap-y-2 text-center">
            <div className="_Title text-[80px] font-bold leading-[80px]">A new era of stock media</div>
            <div className="_Subtitle text-[24px] font-medium">
              The most diverse AI-curated stock library with over {mediaCount ? mediaCount : '...'} curated image, 3D,
              audio and video assets.
            </div>
          </div>
          <SearchBar />
        </div>
      </div>
    </div>
  );
}
