import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from '../../../types/redux/RootState';

const selectSlice = (state: RootState) => state?.search || initialState;

export const selectSearchStatus = createSelector([selectSlice], state => state.status);

export const selectSearchResults = createSelector([selectSlice], state => state.results);
